body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dot {
	position: absolute;
}

.tridi-dot:after {
	-webkit-animation-name: 'ripple'; /*动画属性名，也就是我们前面keyframes定义的动画名*/
	-webkit-animation-duration: 1s; /*动画持续时间*/
	-webkit-animation-timing-function: ease; /*动画频率，和transition-timing-function是一样的*/
	-webkit-animation-delay: 0s; /*动画延迟时间*/
	-webkit-animation-iteration-count: infinite; /*定义循环资料，infinite为无限次*/
	-webkit-animation-direction: normal; /*定义动画方式*/
}

@keyframes ripple {
	0% {
		left: 5px;
		top: 5px;
		opcity: 75;
		width: 0;
		height: 0;
	}
	100% {
		left: -20px;
		top: -20px;
		opacity: 0;
		width: 50px;
		height: 50px;
	}
}
